let translations = {
  'administrator': 'администратор',
  'expert': 'эксперт',
  'oceanographer': 'океанолог',
  'seismologist': 'сейсмолог',
  'output_message' : 'исходщее',
  'input_message' : 'входящее',
  'output_confirmation' : 'исходщее подтверждение',
  'input_confirmation' : 'входящее подтверждение'
}

export function translate(text) {
  if (!text) return 'ошибка текста'
  return translations[text.toLowerCase()] ? translations[text.toLowerCase()] : text
}